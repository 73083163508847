



















































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(_, { root }) {
    const { getLanguageVersion } = useMisc({ root });
    const state = reactive({
      loading: false,
      config: {},
    });

    const getConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/poster-session-module`)
        .then(({ data }) => {
          state.config = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(getConfig);

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };
    return { getLanguageVersion, state, getFile };
  },
});
